@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  & .phoneInput {
    width: 100%;
    height: 48px;
  }
}

.referralCode {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  & .referralCodeInput {
    width: 100%;
    height: 48px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.privacyCompliance {
  margin-bottom: 12px;
  font-size: 12px;
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}